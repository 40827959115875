export const environment = {
    production: true,
    inactive: false,

  
    BASE_LA: 'https://staging-api.creditotal.online',
    BASE_FORNT: 'https://staging-console.creditotal.online',
    BASE_FORNT_CLIENT: 'https://staging-app.creditotal.online',
  
    redirectRoute:"/home",
    searchClient:"/console/financial-payments",
    psicometrico:"/console/psicometrico",
    initRoute:"/home",
  
    externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',
    
    loginRoute:"/login",
    logoutRoute:"/logout",
  
    socketBase:{
      SOCKET_ENDPOINT: 'https://staging-socket-api.creditotal.online/'
    },
    
    ClieID:"12",
    CED:"11314836",
    IP_LOCAL:"34.225.156.128"
    
  
  };
  